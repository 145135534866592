











































































































































import { number } from "mathjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
type step = {
  slot: string;
  title: string;
};
import { BopStatus } from "@/utils/Enums";
import Bop from "@/models/Bop";

@Component
export default class Wizard extends Vue {
  @Prop({ required: true }) private steps!: Array<step>;
  @Prop({ required: true }) private showSave!: boolean;
  @Prop({ required: false }) private nextButtonText!: string;
  @Prop({ required: false }) private previousButtonText!: string;
  @Prop({ required: false }) private saveButtonText!: string;
  @Prop({ required: false }) private startIndex!: number;
  @Prop({ required: false }) private saveDisabled!: boolean;
  @Prop({ required: false }) private sendDisabled!: boolean;
  @Prop({}) private onNext!: any;
  @Prop({}) private onPrevious!: any;
  @Prop({ required: false }) private bopStatus!: string;
  @Prop({ required: false }) private canValidateBop!: boolean;
  @Prop({ required: false }) private canApproveBop!: boolean;

  @Prop({ required: false, default: false }) private isExportingPdf!: boolean;

  private activeSlotIndex: number = 0;

  //methods
  private next() {
    if (this.activeSlotIndex >= this.steps.length) {
      return;
    }
    if (this.onNext && typeof this.onNext == "function") {
      const nexStep = this.activeSlotIndex + 1;
      const check = this.onNext(this.activeSlotIndex, nexStep);
      if (!check) {
        //returned false. don't do anything
        return;
      }
    }
    this.activeSlotIndex++;
  }

  private previous() {
    if (this.activeSlotIndex <= 0) {
      return;
    }
    if (this.onPrevious && typeof this.onPrevious == "function") {
      const nexStep = this.activeSlotIndex - 1;
      if (!this.onPrevious(this.activeSlotIndex, nexStep)) {
        //returned false. don't do anything
        return;
      }
    }
    this.activeSlotIndex--;
  }
  private navigate(index: number) {
    this.goTo(index);
  }
  private goTo(index: number) {
    if (index >= this.steps.length || index < 0) {
      return;
    }
    if (index > this.activeSlotIndex) {
      if (this.onNext && typeof this.onNext == "function") {
        const check = this.onNext(this.activeSlotIndex, index);
        if (!check) {
          //returned false. don't do anything
          return;
        }
      }
    } else {
      if (this.onPrevious && typeof this.onPrevious == "function") {
        if (!this.onPrevious(this.activeSlotIndex, index)) {
          //returned false. don't do anything
          return;
        }
      }
    }

    this.activeSlotIndex = index;
    // this.$emit("navigation", this.activeSlotIndex);
  }

  // Bop actions
  private save() {
    this.$emit("save");
  }

  private exportBopPdf() {
    this.$emit("exportBopPdf");
  }

  private approveBop() {
    this.$emit("approveBop");
  }

  private declineBop() {
    this.$emit("declineBop");
  }
  //////////////

  private getStateClass(stepIndex: number) {
    if (stepIndex < this.activeSlotIndex) {
      return " completed ";
    }
    if (stepIndex === this.activeSlotIndex) {
      return " active ";
    }
    return "";
  }
  private get isAwaitingApproval(): boolean {
    return this.bopStatus === BopStatus.AwaitingLaunchWorkApproval;
  }
  private get isReadOnly(): boolean {
    return Bop.readOnlyStatuses.includes(this.bopStatus);
  }

  //hooks
  private mounted() {
    this.activeSlotIndex = this.startIndex;
  }
}
